import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { BreakPoint } from "utils/screenSize";

import { Localization } from "constant";
import {
	allowedDedupeModifyRoles,
	allowedDedupeViewRoles,
} from "containers/Deduplication/constant";
import { useMediaQuery } from "@mui/material";

type PROPS = {
	size: {
		width: number;
		height: number;
		screen: BreakPoint;
	};
	setShowSidebar: (val: boolean) => void;
	supportChatLoading: boolean;
	onSupportChatClick: () => void;
};

const Sidebar = ({
	setShowSidebar,
	supportChatLoading = false,
	onSupportChatClick,
}: PROPS) => {
	const user: {
		id: number;
		role_name: string;
		role_type_name: string;
		lending_company: string;
	} = JSON.parse(localStorage.getItem("tvsd.los.user"));
	const currentUserRole = user?.role_name;
	// Submenu toggle handler
	const toggleSubMenu = (event) => { };
	const menus = (() => {
		const items: any = [];
		// items.push({
		//   name: "Dashboard",
		//   href: "/dashboard",
		//   link: "/dashboard",
		//   icon: "icon_dashboard.svg",
		// });
		items.push({
			name: "Loans",
			href: "/loans",
			link: "/loans",
			iconActive: "icon-preloan-filled.svg",
			iconInactive: "icon-preloan-outline.svg",
		});
		if (!Localization.isProduction && Localization.isUsingWheeltekConfig) {
			if (allowedDedupeViewRoles.includes(currentUserRole)) {
				items.push({
					name: "Dedupe",
					href: "/dedupe",
					link: "/dedupe",
					iconActive: "icon-dedupe-solid.svg",
					iconInactive: "icon-dedupe-outline.svg",
					viewOnly: !allowedDedupeModifyRoles.includes(currentUserRole),
				});
			}
		}
		// items.push({
		//   name: "Tasks",
		//   href: "/task",
		//   link: "/task",
		//   icon: "clock.png",
		// });
		// items.push({
		//   name: "New Application",
		//   href: "/step1-application",
		//   link: "/step1-application",
		//   icon: "icon_loans.svg",
		// });
		return items;
	})();
	const [isOpen, setIsOpen] = useState(false);
	const isNotMobile = useMediaQuery("(min-width:600px)");
	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const closeSidebar = () => {
		setIsOpen(false);
	};


	const renderNavMenu = (el: any) => {
		const isActive =
			el.link === "/dedupe"
				? activeRoute.startsWith("/dedupe")
				: activeRoute === "/" ||
				activeRoute.startsWith("/loans") ||
				activeRoute === "/new-customer" ||
				activeRoute === "/loan-list" ||
				activeRoute === "/customer-profile" ||
				activeRoute.startsWith("/loan-details");

		return (
			<div>
				<SidebarMenu active={isActive}>
					<img
						src={`${process.env.PUBLIC_URL}/images/${isActive ? el.iconActive : el.iconInactive
							}`}
						alt="icon"
						width="28"
						height="28"
						onClick={() => toggleSubMenu(el)}
					/>
					{!el?.link ? (
						<div
							className="ml-1 align-self-center"
							onClick={() => {
								toggleSubMenu(el);
							}}
							aria-hidden="true">
							{el.name}
						</div>
					) : (
						<SideBtn
							active={isActive}
							onClick={(event) => {
								// event.stopPropagation();
								// if (["md", "s", "xs"].includes(size.screen))
								// setShowSidebar(false);
								// history.push(el.link);
							}}>
							{el.name}
						</SideBtn>
					)}
				</SidebarMenu>
				{renderArrow(el, el?.items, el?.name)}
			</div>
		);
	};

	const renderArrow = (el, items, name) => {
		if (items) {
			return (
				<div
					className="table-arrow-down align-self-center"
					onClick={() => toggleSubMenu(el)}
					aria-hidden="true"
				/>
			);
		}
		return <></>;
	};
	// const version = require("../../../package.json").version;

	const route = useRouteMatch();
	const location = useLocation();
	const activeRoute = location.pathname;

	const logoImageSource = () => {
		switch (true) {
			case Localization.isUsingMotoraceConfig && Localization.isForDemo:
			case Localization.isUsingWheeltekConfig && Localization.isForDemo:
				return `${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`;
			case Localization.isUsingCelestinaConfig:
				return `${process.env.PUBLIC_URL}/images/${Localization.logoCelestinaFilename}`;
			case Localization.isUsingMotoraceConfig:
				return `${process.env.PUBLIC_URL}/images/${Localization.logoMotoraceFilename}`;
			case Localization.isUsingWheeltekConfig:
				return `${process.env.PUBLIC_URL}/images/${Localization.logoWheeltekFilename}`;
			case Localization.isUsingNewNemarConfig:
				return `${process.env.PUBLIC_URL}/images/${Localization.logoNewnemarFilename}`;
			default:
				return `${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`;
		}
	};

	return (
		<>
			{!isNotMobile && <Hamburger style={{ fontSize: "40px" }} onClick={toggleSidebar}>&#9776;</Hamburger>}
			{isOpen && <Backdrop isOpen={isOpen} onClick={closeSidebar} />}
			<SidebarComponent isOpen={isNotMobile ? true : isOpen}>
				<div>
					<div style={{ textAlign: "center" }}>
						<Link to="/">
							<img
								src={logoImageSource()}
								alt={Localization.clientName}
								// height="30"
								// width="40%"
								className="mt-3"
							/>
						</Link>
					</div>
					<ListGroupStyle style={{ paddingTop: "24px" }}>
						<TitleText>Loan Origination</TitleText>
						{menus?.map((el: any) => (
							<ListGroupItem
								tag={el.href ? "a" : "div"}
								href={el?.href}
								key={el.name}
								className={`parent ${activeRoute.includes(el.link) ? "is-active" : ""}`}>
								{renderNavMenu(el)}
								{
									el?.items && (
										<ListGroupStyle>
											{el?.items?.map((child: any, index: number) => (
												<ListGroupItem
													key={child.href}
													className={`sub ${activeRoute.includes(child.link) ? "is-active" : ""
														}`}
													style={{}}>
													<ul style={{ paddingLeft: "20px" }}>
														<li
															style={{
																paddingTop: "12px",
																paddingBottom: "12px",
																listStyle: "disc",
																color: "#999999",
															}}>
															{renderNavMenu(child)}
														</li>
													</ul>
												</ListGroupItem>
											))}
										</ListGroupStyle>
									)
									// eslint-disable-next-line @typescript-eslint/indent
								}
							</ListGroupItem>
						))}
					</ListGroupStyle>
				</div>
				<ListGroupStyle>
					<div>
						<div className="d-flex justify-content-center mb-3 flex-column">
							<FooterText className="ml-2">Powered by</FooterText>
							<img
								className="d-flex align-self-center pb-1"
								src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
								alt="TVS Digital"
							/>
							{/* <VersionTxt>VERSION {version}</VersionTxt> */}
						</div>
						<div className="d-flex justify-content-center mb-2 flex-column">
							{/* <FooterText className="mr-2">Powered by</FooterText> */}
							<img
								className="d-flex align-self-center pb-1"
								src={`${process.env.PUBLIC_URL}/images/${Localization.logoApecPrpFilename}`}
								alt="TVS Digital"
							/>
							{/* <VersionTxt>VERSION {version}</VersionTxt> */}
						</div>
					</div>
					<Hrline />
					<SupportChatSection
						onClick={() =>
							onSupportChatClick && !supportChatLoading && onSupportChatClick()
						}>
						<img
							src={`${process.env.PUBLIC_URL}/images/icon-help-outline.svg`}
							alt="Icon Help"
						/>
						<SupportChatSectionTxt>
							{supportChatLoading ? "Loading Chat..." : "Support Chat"}
						</SupportChatSectionTxt>
					</SupportChatSection>
					<Hrline />
					<div className="d-flex justify-content-center p-2">
						<LegalLink href={Localization.PRIVACY_POLICY_URL} target="_blank">
							Privacy Policy
						</LegalLink>
						<LegalLink href={Localization.TERMS_OF_USE_URL} target="_blank">
							Terms of use
						</LegalLink>
						<LegalLink href={Localization.COOKIE_POLICY_URL} target="_blank">
							Cookie Policy
						</LegalLink>
					</div>
				</ListGroupStyle>
			</SidebarComponent>
		</>
	);
};

const SupportChatSection = styled.div`
	height: 56px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const SupportChatSectionTxt = styled.span`
	margin-left: 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
	color: #3548c9;
`;

const TitleText = styled.div`
	border-top: 1px solid #e7e9ed;
	padding: 16px;
	font-size: 12px;
	font-weight: 700;
	color: #395278;
	text-align: start;
`;

const Hrline = styled.hr`
	border: 0;
	border-bottom: 1px solid #dddddd;
	display: block;
	width: 100%;
	margin: 0;
`;

const VersionTxt = styled.span`
	font-size: 7px;
	font-weight: 400;
	line-height: 16px;
	text-align: center;
	color: #666666;
`;

const SidebarComponent = styled.div<{ isOpen?: boolean }>`
	position: fixed;
	top: 66px;
	left: ${({ isOpen }) => (isOpen ? "0" : "-260px")};
	width: 256px;
	height: calc(100% - 66px);
	background-color: #fff;
	transition: left 0.3s ease-in-out;
	box-shadow: ${({ isOpen }) => (isOpen ? "2px 0px 10px rgba(0, 0, 0, 0.1)" : "none")};
	z-index: 1001;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem;

	@media (max-width: 820px) {
		width: 80%;
		top: 0px;
		left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
		height: 100%;
	}
`;



const Hamburger = styled.button`
	position: fixed;
	top: 15px;
	left: 15px;
	background: none;
	color: white;
	border: none;
	font-size: 24px;
	cursor: pointer;
	z-index: 1000;
`;

const CloseButton = styled.button`
	position: absolute;
	top: 15px;
	right: 15px;
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
`;

const Backdrop = styled.div<{ isOpen: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999; 
	display: ${({ isOpen }) => (isOpen ? "block" : "none")}; 
	pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")}; 
`;

const ListGroupStyle = styled(ListGroup)`
	.list-group {
		margin: 0;
	}
	.list-group-item {
		background-color: transparent;
		padding: 0;
		border-top: none;
		border-right: none;
		border-bottom: none;
		border-left: 1px solid transparent;
		margin-left: -1px;
		.btn {
			font-weight: 500;
			color: #333333;
		}
		&.sub {
			padding-left: 27px;
			.btn {
				font-size: 14px;
				font-weight: 500;
				color: #333333;
			}
			&.is-active {
				a,
				.btn {
					font-size: 14px;
					font-weight: 500;
					color: #333333;
				}
				border-radius: 0px 32px 32px 0px;
				background-color: #e4e4e4;
				margin-right: 8px;
			}
		}
		> div {
			font-weight: 500;
			color: #333333;
			align-self: center;
		}
		&.parent.is-active {
			> div {
				border-radius: 0px 32px 32px 0px;
				margin-right: 8px;
			}
		}#e4e4e4
	}
`;

const SidebarMenu = styled.div<{ active: boolean }>`
	padding: 14px 8px 14px 14px;
	height: 52px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background-color: ${(props) =>
		props.active ? "#E8E9F9" : "transparent"} !important;
	border-radius: 100px;
`;

const SideBtn = styled(Button)`
	padding-left: 4px !important;
	border: none;
	color: ${(props) => (props.active ? "#3548C9" : "#395278")} !important;
	background-color: transparent !important;
	font-size: 14px;
	text-decoration: none;
	&:hover {
		text-decoration: none !important;
	}
`;

const FooterText = styled.div`
	font-weight: 400;
	font-size: 10px;
	line-height: 14px;
	color: #74849c;
	text-align: center;
	padding: 6px;
`;

const LegalLink = styled.a`
	font-size: 8px;
	line-height: 14px;
	color: #99A6B9;
	font-weight: 400;
	margin-right: 8px;
	&:hover {
		color: #99A6B9;
	}
`;
export default Sidebar;
